$(function () {
  try {
    Typekit.load();
  } catch (e) { }
});

jQuery(document).ready(function ($) {

  var random = Math.floor(Math.random() * $('.testimonial-content').length);

  $($('.testimonial-content')[random]).show();

  NavWrap.init();

  // hash focus onload
  if (document.location.hash) {
    var myAnchor = document.location.hash;
    $(myAnchor).attr('tabindex', -1).on('blur focusout', function () {
      $(this).removeAttr('tabindex');
    }).focus();
  }

  // hash focus inline
  $(window).bind('hashchange', function () {
    var hash = "#" + window.location.hash.replace(/^#/, '');
    if (hash != "#") {
      $(hash).attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      }).focus();
    }
    else {
      $("#header").attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      }).focus();
    }
  });

  $('.search-open, .search-close').on('click', function () {
    $('.search-button').toggleClass('active');
    $('.search-pane').toggleClass('active');
    $('body').toggleClass('fixed-body');
  });

  $('.search-action').on('click', function (e) {
    e.preventDefault();
    var query = $('.search-input').val();
    window.location.href = "/Search/?q=" + query;
  });

  $('.btn-search').on('click', function (e) {
    e.preventDefault();
    var query = $('.search-pane input[type="text"]').val();
    window.location.href = "/Search/?q=" + query;
  });

  $('input.search-input').keypress(function (e) {
    if (e.which == 13) {
      e.preventDefault();
      var query = $(this).val();
      window.location.href = "/Search/?q=" + query;
    }
  });

  $('.mobile-nav-toggle').on('click', function () {
    $('nav.primary').toggleClass('active');
    $('body').toggleClass('fixed-body');
  });

  $('nav.primary li.has_children').on('click', function () {
    $(this).toggleClass('active');
  });

  $('.fancy-dropdown').on('click', function () {
    $('.retreat-centers').toggleClass('open');
  });

  $('.show-newsletter-signup').on('click', function () {
    //Show Modal
    $('.newsletter.modal-container').addClass('active');
    $('body').addClass('fixed-body');
  });

  $('.modal-close').on('click', function () {
    $('.modal-container').removeClass('active');
    $('body').removeClass('fixed-body');
  });

  $('body').scroll(function () {
    if ($(this).scrollTop() >= 50) {
      $('.back-to-top').fadeIn(200);
    } else {
      $('.back-to-top').fadeOut(200);
    }
  });

  $('.back-to-top').click(function () {
    $('body').animate({ scrollTop: 0 }, 500);
  });

});

