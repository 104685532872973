var s,
  NavWrap = {

    settings: {
      overlapBehavior: 'wrap',
      navElement: $('nav.primary'),
      logo: $('.logo'),
      headerElement: $('.header'),
      logoPadding: 20
    },

    init: function () {
      s = this.settings;
      this.calcNavWrap();

      $(window).on('resize', function () {
        NavWrap.calcNavWrap();
      });
    },

    calcNavWrap: function () {
      var navWidth = s.navElement.width(),
        logoWidth = s.logo.width(),
        headerWidth = s.headerElement.width();

      logoRightPosition = s.logo.offset().left + logoWidth;
      navLeftPosition = s.navElement.offset().left;

      if (navLeftPosition <= (logoRightPosition + s.logoPadding)) {
        s.headerElement.addClass('breakNav');
        console.log(navLeftPosition + " <= " + (logoRightPosition + s.logoPadding));
      } else {
        s.headerElement.removeClass('breakNav');
        console.log(navLeftPosition + " > " + (logoRightPosition + s.logoPadding));
      }

    }

  };